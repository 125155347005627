import React from 'react';
import { useHistory } from 'react-router-dom';

import styled from 'styled-components';
import Switch from 'react-switch';
import { apiBase, awardCategories, colors, mediaBase } from 'variables';

import { apiClient } from 'helpers/APIClient';
import { toast } from 'react-toastify';
import { toastSettings } from 'helpers/toastSettings';
import { Loader } from 'components/common/Loader';
import { Project } from 'types';
import { IdCell } from 'components/table/IdCell';
import { ProjectCell } from 'components/table/ProjectCell';
import { CategoryCell } from 'components/table/CategoryCell';
import { DeaScoreCell } from 'components/table/DEAScoreCell';
import { DEAwardCell } from 'components/table/DEAwardCell';
import { CommentCell } from 'components/table/CommentCell';
import { GalleryPublishCell } from 'components/table/GalleryPublishCell';
import { VotesCell } from 'components/table/VotesCell';
import { TotalCell } from 'components/table/TotalCell';
import { AverageCell } from 'components/table/AverageCell';
import { TableHeader } from 'components/table/TableHeader';
import { PrimaryButton } from 'components/common/Buttons';
import StyledSelect from 'components/common/Select';
import { CommentsModal } from 'components/modals/CommentsModal';

const HeaderSection = styled.div`
  width: 100%;
  margin-bottom: 36px;
  display: flex;
  justify-content: center;
`;

const Category = styled.span<{ active: boolean }>`
  font-size: 18px;
  cursor: pointer;
  color: ${props => (props.active ? 'white' : colors.secondary)};

  :not(:last-child) {
    margin-right: 32px;
  }
`;

const Table = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

const TableRow = styled.div`
  display: flex;
  align-items: center;
  min-height: 100px;

  :hover {
    background-color: ${colors.background};
  }
`;

const SwitchWrapper = styled.label`
  display: flex;
  align-items: center;

  span {
    margin-right: 16px;
  }
`;

export const ProjectTable = (): JSX.Element => {
  const history = useHistory();

  const [isFetching, setIsFetching] = React.useState(false);
  const [isFetchingSettings, setIsFetchingSettings] = React.useState(false);
  const [selectedEdition, setSelectedEdition] = React.useState<
    { label: string; value: string } | undefined
  >(undefined);

  const [projects, setProjects] = React.useState<Project[]>([]);
  const [activeCategory, setActiveCategory] = React.useState(awardCategories[0].key);
  const [checked, setChecked] = React.useState<boolean | null>(null);
  const [isUpdatingSettings, setIsUpdatingSettings] = React.useState(false);

  const [commentsModalProjectId, setCommentsModalProjectId] = React.useState<number | null>(null);

  React.useEffect(() => {
    setIsFetching(true);
    setIsFetchingSettings(true);

    apiClient
      .get(`${apiBase}admin_panel/projects/?award_category=${activeCategory}`)
      .then(({ data }) =>
        setProjects(data.filter((project: Project) => project.awardCategory.includes(activeCategory))),
      )
      .then(() => setIsFetching(false))
      .catch(() => toast('Failed to fetch projects', toastSettings));

    apiClient
      .get(`${apiBase}sites/ `)
      .then(({ data }) => {
        setChecked(data.publishTotalScore);
      })
      .then(() => setIsFetchingSettings(false))
      .catch(() => toast('Failed to fetch settings', toastSettings));
  }, [activeCategory]);

  const refresh = async () => {
    await apiClient
      .get(`${apiBase}admin_panel/projects/?award_category=${activeCategory}`)
      .then(({ data }) =>
        setProjects(data.filter((project: Project) => project.awardCategory.includes(activeCategory))),
      );
  };

  const refreshSettings = async () => {
    await apiClient.get(`${apiBase}sites/ `).then(({ data }) => setChecked(data.publishTotalScore));
  };

  const projectEditionsOptions = projects
    .reduce(
      (acc, curr) => (acc.includes(curr.projectEdition) ? acc : [...acc, curr.projectEdition]),
      [] as string[],
    )
    .sort((a, b) => b.localeCompare(a))
    .map(edition => ({ value: edition, label: edition }))
    
  React.useEffect(() => {
    if (selectedEdition) return;

    setSelectedEdition(projectEditionsOptions[0]);
  }, [projectEditionsOptions]);

  if (isFetching || isFetchingSettings) return <Loader />;

  return (
    <>
      <HeaderSection>
        {checked !== null && (
          <SwitchWrapper>
            <span>Publish global score</span>
            <Switch
              onChange={async () => {
                setIsUpdatingSettings(true);
                try {
                  await apiClient.put(`${apiBase}sites/`);
                  await refreshSettings();
                } catch {
                  toast('Failed to update settings', toastSettings);
                } finally {
                  setIsUpdatingSettings(false);
                }
              }}
              checked={checked}
              disabled={isUpdatingSettings}
            />
          </SwitchWrapper>
        )}
      </HeaderSection>
      <HeaderSection>
        <PrimaryButton onClick={() => history.push('/add-project')}>Add project</PrimaryButton>
      </HeaderSection>
      <HeaderSection>
        <StyledSelect
          options={projectEditionsOptions}
          value={selectedEdition}
          onChange={setSelectedEdition}
        />
      </HeaderSection>
      <HeaderSection>
        {awardCategories.map(category => (
          <Category
            key={category.key}
            active={category.key === activeCategory}
            onClick={() => setActiveCategory(category.key)}
          >
            {category.label}
          </Category>
        ))}
      </HeaderSection>

      <Table>
        <TableHeader />
        {[...projects]
          .sort((a, b) => (b.score || 0) - (a.score || 0))
          .filter(project => project.projectEdition === selectedEdition?.value)
          .map(project => (
            <TableRow key={project.id}>
              <IdCell id={project.id} />
              <ProjectCell
                image={`${mediaBase}${project?.images[0]?.image}`}
                name={project.projectName}
                company={project.companyName}
                email={project.email}
              />
              <CategoryCell category={project.participantCategory} />

              <DeaScoreCell deaScore={project.deaScore} refresh={refresh} projectId={project.id} />

              <GalleryPublishCell
                published={project.galleryPublish}
                refresh={refresh}
                projectId={project.id}
              />

              <DEAwardCell
                projectId={project.id}
                labels={project.labels}
                refresh={refresh}
                category={activeCategory}
              />
              <CommentCell
                commented={project.comments}
                openCommentsModal={() => setCommentsModalProjectId(project.id)}
              />
              <VotesCell votes={project.votes} />
              <TotalCell total={project.total} />
              <AverageCell score={project.score} />
            </TableRow>
          ))}
      </Table>
      <CommentsModal
        projectId={commentsModalProjectId}
        closeModal={() => setCommentsModalProjectId(null)}
      />
    </>
  );
};
